import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './AppStoresBanner.module.scss';
import { IoCloseCircle } from 'react-icons/io5';
import iosQRCode from '../../assets/images/ios-qr-code.png';
import androidQRCode from '../../assets/images/android-qr-code.png';
import MarketingFooterApple from '../../assets/images/MarketingFooterApple.png';
import MarketingFooterGoogle from '../../assets/images/MarketingFooterGoogle.png';

interface Props {
    showBanner: boolean;
    setShowBanner: Dispatch<SetStateAction<boolean>>;
}

const AppStoresBanner: FC<Props> = ({ showBanner, setShowBanner }) => {
    if (!showBanner) return null;
    return (
        <div
            className={styles.modal}
            onClick={() => setShowBanner(false)}
        >
            <div
                className={styles.container}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.title}>
                    Download the SellCorp app
                </div>
                <div className={styles.rowContainer}>
                    <div className={styles.imagesContainer}>
                        <div className={styles.qrCodeContainer}>
                            <a
                                href="https://apps.apple.com/ro/app/sellcorp/id6446894441"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className={styles.qrCode}
                                    src={iosQRCode}
                                />
                            </a>
                        </div>
                        <div
                            className={styles.storeImageContainer}
                            onClick={() => {
                                window.open('https://apps.apple.com/ro/app/sellcorp/id6446894441', '_blank');
                            }}
                        >
                            <img
                                className={styles.appleImage}
                                src={MarketingFooterApple}
                            />
                        </div>
                    </div>
                    <div className={styles.imagesContainer}>
                        <div className={styles.qrCodeContainer}>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className={styles.qrCode}
                                    src={androidQRCode}
                                />
                            </a>
                        </div>
                        <div
                            className={styles.storeImageContainer}
                            onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US', '_blank');
                            }}
                        >
                            <img
                                className={styles.googleImage}
                                src={MarketingFooterGoogle}
                            />
                        </div>
                        {/*  */}
                    </div>
                </div>
                <div
                    className={styles.closeIconContainer}
                    onClick={() => setShowBanner(false)}
                >
                    <IoCloseCircle className={styles.closeIcon} />
                </div>
            </div>
        </div>
    );
};

export default AppStoresBanner;
