import React from 'react';
import styles from './MarketingPage7.module.scss';
import ThickDivider from '../layout/ThickDivider';
import MarketingPage7Image from '../../assets/images/MarketingPage7Image.png';

const MarketingPage7 = () => (
    <div className={styles.container}>
        <div className={styles.rowContainer}>
            <div>
                <div className={styles.title}>{'Investors? Discover\nstartups at the flick of\na finger'}</div>
                <div className={styles.subtitle}>{'Discover founder-verified startups,\nno algorithms needed. Search manually based\non your preferences and explore real\nopportunities.'}</div>
            </div>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={MarketingPage7Image}
                />
            </div>
        </div>
        <ThickDivider />
    </div>
);

export default MarketingPage7;
