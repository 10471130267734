import React from 'react';
import styles from './MarketingPage1Background.module.scss';
import Row1 from '../../assets/images/Row1.png';
import Row2 from '../../assets/images/Row2.png';
import Row3 from '../../assets/images/Row3.png';

const MarketingPage1Background = () => (
    <div
        className={styles.container}
    >
        <img
            className={styles.row1}
            src={Row1}
        />
        <img
            className={styles.row2}
            src={Row3}
        />
        <img
            className={styles.row3}
            src={Row2}
        />
        <img
            className={styles.row4}
            src={Row1}
        />
        <img
            className={styles.row5}
            src={Row3}
        />
        <div
            className={styles.overlay}
        />
    </div>
);

export default MarketingPage1Background;
