import React, {
    createContext, Dispatch, ReactNode, SetStateAction, useState,
} from 'react';

const ToastNotificationContext = createContext<{
    notification: NotificationType | null;
    setNotification: Dispatch<SetStateAction<NotificationType | null>>;
} | null>(null);
const { Provider } = ToastNotificationContext;

export type NotificationStyleType = 'success' | 'warning' | 'error';

export type NotificationType = {
    id: 'companyPublished' | 'companyUnpublished' | 'reportedSuccessfully' | 'somethingWrong';
    title?: string;
    message?: string;
    color?: string;
    type: NotificationStyleType
    duration?: number;
};

export const ToastNotificationProvider = ({ children }: { children: ReactNode }) => {
    const [notification, setNotification] = useState<NotificationType | null>(null);

    return (
        <Provider value={{
            notification,
            setNotification,
        }}
        >
            {children}
        </Provider>
    );
};

export default ToastNotificationContext;
