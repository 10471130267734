import React from 'react';
import styles from './MarketingFooter.module.scss';
import MarketingFooterApple from '../../assets/images/MarketingFooterApple.png';
import MarketingFooterGoogle from '../../assets/images/MarketingFooterGoogle.png';
import { FaSquareXTwitter, FaLinkedin } from 'react-icons/fa6';
import { FaMedium } from 'react-icons/fa';
import ThickDivider from './ThickDivider';
import { Link } from 'react-router-dom';
// import { AiFillMediumSquare } from 'react-icons/ai';

const documetns = [
    {
        url: '/terms',
        title: 'Terms of Service',
    },
    {
        url: 'mailto:info@sellcorporation.com',
        title: 'Contact Us',
        isEmai: true,
    },
    {
        url: '/privacy',
        title: 'Privacy Notice',
    },
    {
        url: '/cookies',
        title: 'Cookie Policy',
    },
];

const MarketingFooter = () => (
    <div className={styles.container}>
        <div className={styles.rowContainer}>
            <div>
                {documetns.map((item) => (
                    <Link to={item.url} key={item.title}>
                        <div className={styles.itemContainer}>
                            <a
                                href={item.url}
                                className={styles.linkItem}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {item.title}
                            </a>
                        </div>
                    </Link>
                ))}
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.platformsContainer}>
                    <div
                        className={styles.imageContainer}
                        onClick={() => {
                            window.open('https://apps.apple.com/ro/app/sellcorp/id6446894441', '_blank');
                        }}
                    >
                        <img
                            className={styles.appleImage}
                            src={MarketingFooterApple}
                        />
                    </div>
                    <div
                        className={styles.imageContainer}
                        onClick={() => {
                            window.open('https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US', '_blank');
                        }}
                    >
                        <img
                            className={styles.googleImage}
                            src={MarketingFooterGoogle}
                        />
                    </div>
                </div>
                <div className={styles.socialMediaContainer}>
                    <div
                        className={styles.iconContainer}
                        onClick={() => {
                            window.open('https://x.com/SellCorpApp', '_blank');
                        }}
                    >
                        <FaSquareXTwitter className={styles.icon} />
                    </div>
                    <div
                        className={styles.iconContainer}
                        onClick={() => {
                            window.open('https://www.linkedin.com/company/sellcorporation', '_blank');
                        }}
                    >
                        <FaLinkedin className={styles.icon} />
                    </div>
                    <div
                        className={styles.iconContainer}
                        onClick={() => {
                            window.open('https://sellcorporation.medium.com/', '_blank');
                        }}
                    >
                        <FaMedium className={styles.icon} />
                    </div>
                </div>
            </div>
        </div>
        <ThickDivider />
    </div>
);

export default MarketingFooter;
