import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './MarketingPage1.module.scss';
import SellcorpBackgroundV2 from '../layout/SellcorpBackgroundV2';
import { FaChevronRight } from 'react-icons/fa6';
import MarketingPage1Background from './MarketingPage1Background';
import MarketingNavBar from '../layout/MarketingNavBar';
import useDevice from '../../hooks/useDevice';

interface Props {
    setShowBanner: Dispatch<SetStateAction<boolean>>;
}

const MarketingPage1: FC<Props> = ({ setShowBanner }) => {
    const { isAndroid, isIOS } = useDevice();

    return (
        <div className={styles.container}>
            <MarketingPage1Background />
            <MarketingNavBar />
            <div className={styles.contentContainer}>
                <div className={styles.title}>WHERE CAPITAL MEETS OPPORTUNITY</div>
                <div className={styles.price}>Discover fundraising startups. Show up for investors.</div>
                <div
                    className={styles.inputContainer}
                >
                    {/* <div className={styles.inputTitle}>Ready to explore? Enter your email to create your membership.</div> */}
                    <div className={styles.inputRow}>
                        {/* For later use, with input */}
                        {/* <input className={styles.input} type="text" placeholder="Email address" />
                    <div className={styles.button}>
                        Get Started
                        <FaChevronRight className={styles.buttonChevron} />
                    </div> */}
                        <div
                            className={styles.glowOnHover}
                            onClick={() => {
                                if (isAndroid) {
                                    window.open('https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US', '_blank');
                                } else if (isIOS) {
                                    window.open('https://apps.apple.com/ro/app/sellcorp/id6446894441', '_blank');
                                } else {
                                    setShowBanner(true);
                                }
                            }}
                        >
                            Get Started
                            <FaChevronRight className={styles.buttonChevron} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketingPage1;
