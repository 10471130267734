import React from 'react';
import styles from './MarketingPage3.module.scss';
import Logo from '../Logo';

const MarketingPage3 = () => (
    <div className={styles.container}>
        <Logo
            style={{
                width: '40vw',
                height: '40vw',
            }}
        />
        <div className={styles.title}>
            Founder-Verified Listings. Real opportunities from the people behind the companies
        </div>
    </div>
);

export default MarketingPage3;
