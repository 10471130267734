import React from 'react';
import styles from './MarketingPage10.module.scss';
import ThickDivider from '../layout/ThickDivider';
import MarketingPage10Image from '../../assets/images/MarketingPage10Image.png';

const MarketingPage10 = () => (
    <div className={styles.container}>
        <div className={styles.rowContainer}>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={MarketingPage10Image}
                />
            </div>
            <div>
                <div className={styles.title}>{'Explore presentations\neverywhere'}</div>
                <div className={styles.subtitle}>{'Watch unlimited opportunities on your phone,\nor tablet and email founders directly to learn\nmore.'}</div>
            </div>
        </div>
        <ThickDivider />
    </div>
);

export default MarketingPage10;
