import React from 'react';
import styles from './ThickDivider.module.scss';

const ThickDivider = () => (
    <div
        className={styles.container}
    />
);

export default ThickDivider;
