import React from 'react';
import styles from './MarketingWarningPage.module.scss';
import ThickDivider from '../layout/ThickDivider';

const content = [
    'Investing involves risks, including the potential loss of capital, illiquidity, and dilution. It is important that investments are made as part of a diversified portfolio and only by investors who fully understand the associated risks. Please seek independent advice where needed.',
    'SellCorp is a business discovery platform that connects investors and entrepreneurs. We do not provide investment services, financial advice, or conduct due diligence. All negotiations, due diligence, and investment decisions are carried out directly between investors and the companies outside of SellCorp.',
    'Access to the platform as an investor is restricted to professional investors only. Investors must meet the qualifications as set out by relevant financial authorities. SellCorp does not endorse or recommend any specific business, and any listings on the platform are for discovery purposes only. Users are responsible for ensuring they comply with local regulations before making any investment decisions.',
    'SellCorporation does not offer legal, financial, or tax advice. For assistance in these areas, please consult a professional adviser.',
    'RISK WARNING - ARTICLE 48 FPO: The content of this promotion has not been approved by an authorised person within the meaning of the FInancial Services and Market Act 2000. Reliance on this promotion for the purpose of engaging in any investment activity may expose an individual to a significant risk of losing all of the property or other assets invested.',
];

const MarketingWarningPage = () => (
    <div className={styles.container}>
        <div className={styles.contentContainer}>
            {content.map((item) => (
                <div
                    key={item}
                    className={styles.textLine}
                >
                    {item}
                </div>
            ))}
        </div>
    </div>
);

export default MarketingWarningPage;
