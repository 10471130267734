/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => (
    <div className={styles.container}>
        <div className={styles.largeTitle}>
            Privacy Notice
        </div>
        <br />
        Thank you for choosing to be part of our community at Corporation Sell Limited("company", "we", "us", "our").We are committed to protecting your personal information and your right to privacy.If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at ionutfurnea @gmail.com.
        <br />
        <br />
        When you visit our website
        {' '}
        <a
            className={styles.link}
            href="https://www.sellcorporation.com/"
            target="_blank"
            rel="noreferrer"
        >
            https://www.sellcorporation.com
        </a>
        , and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.
        <br />
        <br />
        This privacy policy applies to all information collected through our website(such as
        {' '}
        <a
            className={styles.link}
            href="https://www.sellcorporation.com/"
            target="_blank"
            rel="noreferrer"
        >
            https://www.sellcorporation.com
        </a>
        ), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").
        <br />
        <br />
        <span className={styles.bold}>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</span>
        <br />
        <br />
        <div className={styles.title}>
            Table of contents:
        </div>
        <br />
        <a className={styles.link} href="#1">1. What information do we collect?</a>
        <br />
        <a className={styles.link} href="#2">2. How do we use your information?</a>
        <br />
        <a className={styles.link} href="#3">3. Will your information be shared with anyone?</a>
        <br />
        <a className={styles.link} href="#4">4. Who will your information be shared with?</a>
        <br />
        <a className={styles.link} href="#5">5. Do we use cookies and other tracking technologies?</a>
        <br />
        <a className={styles.link} href="#6">6. How long do we keep your information?</a>
        <br />
        <a className={styles.link} href="#7">7. How do we keep your information safe?</a>
        <br />
        <a className={styles.link} href="#8">8. Do we collect information from minors?</a>
        <br />
        <a className={styles.link} href="#9">9. What are your privacy rights?</a>
        <br />
        <a className={styles.link} href="#10">10. Controls for do-not-track features</a>
        <br />
        <a className={styles.link} href="#11">11. Do California residents have specific privacy rights?</a>
        <br />
        <a className={styles.link} href="#12">12. Do we make updates to this notice?</a>
        <br />
        <a className={styles.link} href="#13">13. How can you contact us about this notice?</a>
        <br />
        <br />
        <div className={styles.title} id="1">What information do we collect?</div>

        <div className={styles.subtitle}>Personal information you disclose to us</div>

        <span className={styles.bold}>In short: </span>
        We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.
        <br />
        <br />
        We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites or otherwise contacting us.
        <br />
        <br />

        The personal information that we collect depends on the context of your interactions with us and the Sites, the choices you make and the products and features you use.The personal information we collect may include the following:
        <br />
        <br />
        <span className={styles.bold}>Name and Contact Data: </span>
        We collect your first and last name, email address, postal address, phone number, and other similar contact data.
        <br />
        <br />
        <span className={styles.bold}>Credentials: </span>
        We collect passwords, password hints, and similar security information used for authentication and account access.
        <br />
        <br />
        <span className={styles.bold}>Payment Data: </span>
        We collect data necessary to process your payment if you make purchases, such as your payment instrument number(such as a credit card number), and the security code associated with your payment instrument.All payment data is stored by our payment processor and you should review its privacy policies and contact the payment processor directly to respond to your questions.
        <br />
        <br />
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
        <br />
        <br />
        Information collected from other sources
        <br />
        <br />
        <span className={styles.bold}>In short: </span>
        We may collect limited data from public databases, marketing partners, and other outside sources.
        <br />
        <br />
        We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties.Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings(such as sponsored links).
        <br />
        <br />
        <div className={styles.title} id="2">How do we use your information?</div>

        <span className={styles.bold}>In short: </span>
        We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and / or your consent.
        <br />
        We use personal information collected via our Sites for a variety of business purposes described below.We process your personal information for these purposes in reliance on our legitimate business interests("Business Purposes"), in order to enter into or perform a contract with you("Contractual"), with your consent("Consent"), and / or for compliance with our legal obligations("Legal Reasons").We indicate the specific processing grounds we rely on next to each purpose listed below.
        <br />
        <br />
        <span className={styles.bold}>We use the information we collect or receive:</span>
        <br />
        <br />
        <span className={styles.bold}>To send you marketing and promotional communications: </span>
        We and / or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences.You can opt - out of our marketing emails at any time(see the
        {' '}
        <a className={styles.link} href="#9">"What are your privacy rights"</a>
        {' '}
        below).
        <br />
        <br />
        <span className={styles.bold}>To send administrative information to you: </span>
        We may use your personal information to send you product, service and new feature information and / or information about changes to our terms, conditions, and policies.
        <br />
        <br />
        <span className={styles.bold}>Fulfill and manage your orders: </span>
        We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Sites.
        <br />
        <br />
        <span className={styles.bold}>Deliver targeted advertising to you: </span>
        We may use your information to develop and display content and advertising(and work with third parties who do so) tailored to your interests and / or location and to measure its effectiveness.
        <br />
        <br />
        <span className={styles.bold}>Administer prize draws and competitions: </span>
        We may use your information to administer prize draws and competitions when you elect to participate in our competitions.
        <br />
        <br />
        <span className={styles.bold}>Request Feedback: </span>
        We may use your information to request feedback and to contact you about your use of our Sites.
        <br />
        <br />

        <span className={styles.bold}>To enable user - to - user communications: </span>
        We may use your information in order to enable user - to - user communications with each user's consent.
        <br />
        <br />

        <span className={styles.bold}>To enforce our terms, conditions and policies.</span>
        <br />
        <br />
        <span className={styles.bold}>To respond to legal requests and prevent harm: </span>
        If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
        <br />
        <br />
        <span className={styles.bold}>For other Business Purposes: </span>
        We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, services, marketing and your experience.
        <br />
        <br />
        <div className={styles.title} id="3">Will your information be shared with anyone?</div>
        <span className={styles.bold}>In short: </span>
        We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
        <br />
        <br />
        We may process or share your data that we hold based on the following legal basis:
        <br />
        <br />
        <span className={styles.bold}>Consent: </span>
        We may process your data if you have given us specific consent to use your personal information for a specific purpose.
        <br />
        <br />
        <span className={styles.bold}>Legitimate Interests: </span>
        We may process your data when it is reasonably necessary to achieve our legitimate business interests.
        <br />
        <br />
        <span className={styles.bold}>Performance of a Contract: </span>
        Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
        <br />
        <br />
        <span className={styles.bold}>Legal Obligations: </span>
        We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena(including in response to public authorities to meet national security or law enforcement requirements).
        <br />
        <br />
        <span className={styles.bold}>Vital Interests: </span>
        We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
        More specifically, we may need to process your data or share your personal information in the following situations:
        Business Transfers.We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        Vendors, Consultants and Other Third - Party Service Providers.We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work.Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts.We may allow selected third parties to use tracking technology on the Sites, which will enable them to collect data about how you interact with the Sites over time.This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity.Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.
        <br />
        <br />
        <span className={styles.bold}>Affiliates: </span>
        We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy.Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
        <br />
        <br />
        <span className={styles.bold}>Business Partners: </span>
        We may share your information with our business partners to offer you certain products, services or promotions.
        <br />
        <br />
        <div className={styles.title} id="4">Who will your information be shared with?</div>
        <span className={styles.bold}>In short: </span>
        We only share information with the following third parties.
        We only share and disclose your information with the following third parties.We have categorised each party so that you may be easily understand the purpose of our data collection and processing practices.If we have processed your data based on your consent and you wish to revoke your consent, please contact us.
        <br />
        <br />
        <span className={styles.bold}>Advertising, Direct Marketing, and Lead Generation: </span>
        Google AdSense
        <br />
        <br />
        <span className={styles.bold}>Website Hosting: </span>
        WordPress.com
        <br />
        <br />
        <span className={styles.bold}>Website Performance Monitoring: </span>
        Firebase Performance Monitoring and Firebase Crash Reporting
        <br />
        <br />
        <span className={styles.bold}>Website Testing: </span>
        Google Play Console and Google Website Optimizer
        <br />
        <br />
        <div className={styles.title} id="5">Do we use cookies and other tracking technologies?</div>
        <span className={styles.bold}>In short: </span>
        We may use cookies and other tracking technologies to collect and store your information.
        <br />
        We may use cookies and similar tracking technologies(like web beacons and pixels) to access or store information.Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.
        <div className={styles.title} id="6">How long do we keep your information?</div>
        <span className={styles.bold}>In short: </span>
        We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
        <br />
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law(such as tax, accounting or other legal requirements).No purpose in this policy will require us keeping your personal information for longer than 1 year.
        <br />
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible(for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        <br />
        <br />
        <div className={styles.title} id="7">How do we keep your information safe?</div>

        <span className={styles.bold}>In short: </span>
        We aim to protect your personal information through a system of organisational and technical security measures.
        <br />
        We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process.However, please also remember that we cannot guarantee that the internet itself is 100 % secure.Although we will do our best to protect your personal information, transmission of personal information to and from our Sites is at your own risk.You should only access the services within a secure environment.
        <br />
        <br />
        <div className={styles.title} id="8">Do we collect information from minors?</div>
        <span className={styles.bold}>In short: </span>
        We do not knowingly collect data from or market to children under 18 years of age.
        <br />
        We do not knowingly solicit data from or market to children under 18 years of age.By using the Sites, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Sites.If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.If you become aware of any data we may have collected from children under age 18, please contact us at ionutfurnea @gmail.com.
        <br />
        <br />
        <div className={styles.title} id="9">What are your privacy rights?</div>
        <span className={styles.bold}>In short: </span>
        In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information.You may review, change, or terminate your account at any time.
        <br />
        In some regions(like the European Economic Area), you have certain rights under applicable data protection laws.These may include the right(i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and(iv) if applicable, to data portability.In certain circumstances, you may also have the right to object to the processing of your personal information.To make such a request, please use the
        {' '}
        <a className={styles.link} href="#13">contact details</a>
        {' '}
        provided below.We will consider and act upon any request in accordance with applicable data protection laws.
        <br />
        If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time.Please note however that this will not affect the lawfulness of the processing before its withdrawal.
        <br />
        If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority.You can find their contact details here:
        {' '}
        <a
            className={styles.link}
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noreferrer"
        >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
        <br />
        <br />
        <span className={styles.bold}>Cookies and similar technologies: </span>
        Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies.If you choose to remove cookies or reject cookies, this could affect certain features or services of our Sites.To opt - out of interest - based advertising by advertisers on our Sites visit
        {' '}
        <a
            className={styles.link}
            href="http://www.aboutads.info/choices/"
            target="_blank"
            rel="noreferrer"
        >
            http://www.aboutads.info/choices/
        </a>
        .
        <br />
        <br />
        <div className={styles.title} id="10">Controls for do-not-track features</div>
        Most web browsers and some mobile operating systems and mobile applications include a Do - Not - Track("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.No uniform technology standard for recognizing and implementing DNT signals has been finalized.As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.
        <br />
        <br />
        <div className={styles.title} id="11">Do California residents have specific privacy rights?</div>
        <span className={styles.bold}>In short: </span>
        Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
        <br />
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information(if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year.If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
        <br />
        If you are under 18 years of age, reside in California, and have a registered account with the Sites, you have the right to request removal of unwanted data that you publicly post on the Sites.To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California.We will make sure the data is not publicly displayed on the Sites, but please be aware that the data may not be completely or comprehensively removed from all our systems.
        <br />
        <br />
        <div className={styles.title} id="12">Do we make updates to this notice?</div>
        <span className={styles.bold}>In short: </span>
        Yes, we will update this policy as necessary to stay compliant with relevant laws.
        <br />
        We may update this privacy policy from time to time.The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible.If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification.We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
        <br />
        <br />
        <div className={styles.title} id="13">How can you contact us about this notice?</div>
        If you have questions or comments about this policy, you may email us at ionutfurnea @gmail.com or by post to:
        Corporation Sell Limited
        103 Barney Cl, Greenwich, London SE7 8ST, UK
        London, England SE7 8ST
        United Kingdom
        <br />
        <br />

        <span className={styles.bold}>How can you review, update, or delete the data we collect from you?</span>
        <br />
        <br />
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances.To request to review, update, or delete your personal information, please submit a request form by clicking
        {' '}
        <a
            className={styles.link}
            href="https://app.termly.io/notify/cc2b1520-c3a6-4bb3-b017-fb4d71f43f21"
            target="_blank"
            rel="noreferrer"
        >
            here
        </a>
        .We will respond to your request within 30 days.
        <br />
        <br />
        <div className={styles.title}>
            Last Updated
        </div>
        Privacy Notice was last updated November 19, 2020
    </div>
);

export default PrivacyPolicy;
