import React, {
    FC, useEffect, useMemo, useState,
} from 'react';
import { BiError } from 'react-icons/bi';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import useNotification from '../../hooks/useNotification';
import styles from './ToastNotification.module.scss';

interface Props {
    onHide?: () => void;
    onPresent?: () => void;
}

const ToastNotificationCard: FC<Props> = ({
    onHide, onPresent,
}) => {
    const [opacity, setOpacity] = useState(1);
    const { notification, setNotification } = useNotification();

    // const iconColor = useMemo(() => {
    //     if (notification?.type === 'error') return WARNING;
    //     if (notification?.type === 'success') return GREEN;
    //     return notification?.color || BLACK;
    // }, [notification]);

    useEffect(() => {
        if (notification) {
            if (onPresent) {
                onPresent();
            }
            setOpacity(1);
            setTimeout(() => {
                setOpacity(0);
                setTimeout(() => {
                    setNotification(null);
                    if (onHide) {
                        onHide();
                    }
                }, 600);
            }, notification?.duration || 3000);
        }
    }, [notification]);

    if (!notification) return null;
    return (
        <div
            onClick={() => {
                setNotification(null);
                if (onHide) {
                    onHide();
                }
            }}
            className={styles.container}
        >
            <div className={styles.content}>
                {notification?.type === 'success' && (
                    <IoMdCheckmarkCircleOutline className={styles.successIcon} />
                )}
                {notification?.type === 'warning' && (
                    <BiError className={styles.warningIcon} />
                )}
                {notification?.type === 'error' && (
                    <BiError className={styles.errorIcon} />
                )}
                <div className={styles.message}>
                    {notification?.message}
                </div>
            </div>
        </div>
    );
};

export default ToastNotificationCard;
