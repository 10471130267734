import React from 'react';
import styles from './MarketingPage2.module.scss';
import MarketingPage2Background from '../../assets/images/MarketingPage2Background.png';

const MarketingPage2 = () => (
    <div className={styles.container}>
        <img
            className={styles.backgroundImage}
            src={MarketingPage2Background}
        />
        <div className={styles.contentContainer}>
            <div className={styles.title}>
                The SellCorp you trust, now for just £10 a month. Cancel at any time.
            </div>
            <div className={styles.subtitle}>
                Or choose an annual plan for £100 to keep your company in front of active investors year-round.
            </div>
            <div className={styles.subtitle}>
                Free for investors — giving you maximum visibility.
            </div>
            {/* <a
                className={styles.subtitleCta}
                href="https://sellcorporationapp.page.link/founders"
                target="_blank"
                rel="noreferrer"
            >
                Learn More
            </a> */}
        </div>
    </div>
);

export default MarketingPage2;
