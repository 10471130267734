import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './MarketingPage6.module.scss';
import ThickDivider from '../layout/ThickDivider';
import MarketingPage6Image from '../../assets/images/MarketingPage6Image.png';
import { FaChevronRight } from 'react-icons/fa6';
import useDevice from '../../hooks/useDevice';

interface Props {
    setShowBanner: Dispatch<SetStateAction<boolean>>;
}

const MarketingPage6: FC<Props> = ({ setShowBanner }) => {
    const { isAndroid, isIOS } = useDevice();

    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.rowContainer}>
                    <div>
                        <div className={styles.title}>{'Intro into any story\nwith one cover image'}</div>
                        <div className={styles.subtitle}>{'Storytell your adventures in a space made just for\nthem - free with your membership.'}</div>
                    </div>
                    <div className={styles.ghostImageContainer} />
                </div>
                <div className={styles.imageContainer}>
                    <img
                        className={styles.image}
                        src={MarketingPage6Image}
                    />
                </div>
                <div className={styles.rowContainer}>
                    <div>
                        <div className={styles.quote}>
                            {'"A picture is worth a thousand words."                       '}
                        </div>
                    </div>
                    <div className={styles.ghostImageContainer} />
                </div>
                <div
                    className={styles.glowOnHover}
                    onClick={() => {
                        if (isAndroid) {
                            window.open('https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US', '_blank');
                        } else if (isIOS) {
                            window.open('https://apps.apple.com/ro/app/sellcorp/id6446894441', '_blank');
                        } else {
                            setShowBanner(true);
                        }
                    }}
                >
                    Discover Their Stories
                    <FaChevronRight className={styles.buttonChevron} />
                </div>
            </div>
            <ThickDivider />
        </div>
    );
};

export default MarketingPage6;
