import React, { useEffect, useState } from 'react';

const useDevice = () => {
    const [isAndroid, setIsAndroid] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        const userAgent = navigator?.userAgent;

        if (/android/i.test(userAgent)) {
            setIsAndroid(true);
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            setIsIOS(true);
        }
    }, []);
    return {
        isAndroid,
        isIOS,
    };
};

export default useDevice;
