import { useContext } from 'react';
import ToastNotificationContext from '../context/ToastNotificationContext';

const useNotification = () => {
    const context = useContext(ToastNotificationContext);

    if (!context) {
        throw new Error('useThemeContext must be used inside the ThemeProvider');
    }

    const { notification, setNotification } = context;

    return ({
        notification,
        setNotification,
    });
};

export default useNotification;
