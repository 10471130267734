import React, { FC, useEffect, useState } from 'react';
import styles from './Logo.module.scss';

interface LogoProps {
    style?: React.CSSProperties;
    withText?: boolean;
    animated?: boolean;
}

const Logo: FC<LogoProps> = ({ style, withText, animated }) => (
    <div
        className={styles.container}
    >
        <img
            className={animated ? styles.animatedImage : styles.image}
            src={require('../assets/images/logo.png')}
            style={style}
        />
        {!!withText && (
            <div className={styles.text}>
                SellCorporation
            </div>
        )}
    </div>
);
export default Logo;
