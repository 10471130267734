import React, { useEffect } from 'react';
import styles from './App.module.scss';
import './input.css';
import AppNavigator from './navigation/AppNavigator';
import { RemoteConfigProvider } from './context/RemoteConfigContext';
import { ToastNotificationProvider } from './context/ToastNotificationContext';
import ToastNotificationCard from './components/cards/ToastNotificationCard';
import CookieBanner from './components/layout/CookieBanner';
import { isMobile } from 'react-device-detect';

function App() {
  // useEffect(() => {
  //   const currentUrl = window.location.href;
  //   if (currentUrl?.includes('heroku')) {
  //     window.location.href = 'https://sellcorporation.com/';
  //     return;
  //   }
  // }, []);

  return (
    <div className={styles.appContainer}>
      <ToastNotificationProvider>
        <ToastNotificationCard />
        {/* <RemoteConfigProvider> */}
        <AppNavigator />
        {/* </RemoteConfigProvider> */}
      </ToastNotificationProvider>
      <CookieBanner />
    </div>
  );
}

export default App;
