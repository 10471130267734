import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MarketingPage from '../screens/MarketingPage';
import TermsAndConditions from '../screens/TermsAndConditions';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import CookiesPolicy from '../screens/CookiesPolicy';

const AppNavigator = () => (
    <Routes>
        <Route path="/" element={<MarketingPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<CookiesPolicy />} />
        <Route path="*" element={<MarketingPage />} />
    </Routes>
);

export default AppNavigator;
