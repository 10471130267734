import React from 'react';
import styles from './MarketingPage5.module.scss';
import ThickDivider from '../layout/ThickDivider';
import MarketingPage5Image from '../../assets/images/MarketingPage5Image.png';

const MarketingPage5 = () => (
    <div className={styles.container}>
        <div className={styles.rowContainer}>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={MarketingPage5Image}
                />
            </div>
            <div>
                <div className={styles.title}>{'Keep what’s private,\npri****'}</div>
                <div className={styles.subtitle}>{"Confidential information stays private until\nyou're ready. Founders control exactly\nwhat details are shared with investors."}</div>
            </div>
        </div>
        <ThickDivider />
    </div>
);

export default MarketingPage5;
